
import { defineComponent } from 'vue';
import tinymce from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
/* Import plugins */
import 'tinymce/plugins/link';

export default defineComponent({
  name: 'UiTextEditor',
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '<p></p>',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editorInstance: {} as any,
  }),
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  methods: {
    setFocus() {
      this.editorInstance.focus();
    },
  },
  mounted() {
    const editor = new tinymce.Editor(
      this.id,
      {
        plugins: 'link',
        menubar: false,
        toolbar: 'bold italic underline link',
        statusbar: false,
        branding: false,
        content_style: `
          .mce-content-body {
            font-family: "OpenSans", sans-serif;
            font-size: 16px;
            line-height: 22px;
            color: #595959;
            margin: 0;
          }
        `,
        setup: (editorInstance: any) => {
          this.editorInstance = editorInstance;
        },
      },
      tinymce.EditorManager,
    );

    editor.render();

    editor.on('init', () => {
      editor.setContent(this.model);
    });

    editor.on('change', () => {
      this.model = editor.getContent({ format: 'html' });
    });

    editor.on('input', () => {
      this.model = editor.getContent({ format: 'html' });
    });
  },
});
