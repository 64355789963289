import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-text-editor", { disabled: _ctx.disabled }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setFocus && _ctx.setFocus(...args)))
  }, [
    _createElementVNode("textarea", {
      id: _ctx.id,
      disabled: _ctx.disabled
    }, null, 8, _hoisted_1)
  ], 2))
}